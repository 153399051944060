import { FunctionComponent } from "react";
import styles from "./Header.module.scss";

const headerTitle = "Third party libraries"

export const Header: FunctionComponent = () => {
  
	return (
    <div className={styles.headerContainer}>
      <h4 className={styles.headerTitle}>{headerTitle}</h4>
      </div>
	);
  
};
