import './App.scss';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import {useEffect,useState} from "react";
import { Errors } from "./components/errors/Errors";
import { PluginList } from './view/thirthPartyLibs';
import { QueryClient, QueryClientProvider } from "react-query";

const errorMessage = "No token found in url!"
const queryClient = new QueryClient();
function App() {
  const [error, setError] = useState<boolean>(false); 
  let location = useLocation();
   let query = new URLSearchParams(location.search);
   const token: string = query.get("token") || "";
   const magazine: string = query.get("magazine") || "";
  

  const {} = useTranslation(); 
  useEffect(() => {
		if (!token) {
			return setError(true);
		}
  }, [token]);

  return (
      <div>
        { error ? (
         <Errors msg={errorMessage} />
        ) : (
          <div className='container'>
             <QueryClientProvider client={queryClient}>

            <PluginList magazine={magazine} token={token}></PluginList>
            </QueryClientProvider>
          </div>)
        }
      </div>
    )
}

export default App;
