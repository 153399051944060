import { FunctionComponent, useState } from "react";
import styles from "./tile.module.scss";
import { Icon, Card, CardHeader, Tooltip } from "@o4c/plugin-components";
import { IPlugin } from "src/interfaces/Plugin.interface";

interface TileItems {
    plugin: IPlugin;
    setPopup: (item: IPlugin) => void;
    content: string
}

export const Tile: FunctionComponent<TileItems> = (props: TileItems) => {
    const { plugin, content, setPopup } = props;
    const [isPopupOpen, setPopupOpen] = useState(false);
    const togglePopup = () => {
        setPopupOpen(!isPopupOpen);
    };

    return (
        <div className={styles.container}>
            <div className={styles.tile} onClick={() => {
                togglePopup()
                setPopup(plugin)
            }}>
                    <Icon color="primary" name="settings" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} 
                    className={styles.iconTitle}
                    />
                <div className={styles.tileTitle}>
                    {plugin.name}
                </div>
            </div>
            {isPopupOpen && (

                <div className={styles.popupOverlay}>

                    <Card className={styles.card}>

                        <CardHeader className={styles.white}>
                            <div className={styles.cardHeader}>
                                <></>
                                <div className={styles.tileTitlePopup}>{plugin.name}</div>
                                <div className={styles.dialogCloseButton}

                                    onClick={togglePopup}>
                                    <Icon name='close-bold'
                                        color="primary"
                                        onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                                </div>
                            </div>
                        </CardHeader>
                        <pre className={styles.mainContent}>
                            {content}
                        </pre>
                    </Card>
                </div>
            )}
        </div>
    );
};

