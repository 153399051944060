import { FunctionComponent } from "react";

import { Spinner } from "@o4c/plugin-components";

import styles from "./FullScreenLoader.module.scss";

interface IFullscreenLoaderProps {
	text?: string;
}

export const FullScreenLoader: FunctionComponent<IFullscreenLoaderProps> = (
	props: IFullscreenLoaderProps
) => {

	return (
		<div className={styles.fullScreenLoader}>
			<Spinner type="dots" color="primary" size="larger" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
		</div>
	);
};
